.PropertyFloorPlanCard_card {
    padding: 10px;
    margin-bottom: 20px;

}

.PropertyFloorPlanCard_title {
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.PropertyFloorPlanCard_master {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.PropertyFloorPlanCard_sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
}
