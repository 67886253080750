.PropertyAccessCard_card {
    height: fit-content;
    margin-bottom: 20px;
    padding: 10px;
}

.PropertyAccessCard_title {
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.PropertyAccessCard_text {
    padding-bottom: 10px;
}
