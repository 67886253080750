.RequestConfirmDetails_body {
    overflow: scroll;
    height: 70vh;
}


.RequestConfirmDetails_title {
    width: 100%;
    border-bottom: solid;
    border-width: 1px;
    border-color: lightgray;
    font-size: xx-large;
    color:rgb(18, 100, 100);
    font-weight: 600;
    margin-bottom: 20px;
}

.RequestConfirmDetails_textField {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.RequestConfirmDetails_img_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

}

.RequestConfirmDetails_img {
    max-width: 60%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 5px;
}

