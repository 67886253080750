.CurrentRequestsCard_card {
    padding: 10px;
    margin-bottom: 20px;
}

.CurrentRequestsCard_title {
    width: 100%;
    font-weight: 600;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.CurrentRequestsCard_list {
    min-height: 60px;

}
