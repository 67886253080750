.PropertyImageCard_card {
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

}

.PropertyImageCard_sub {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

}

.PropertyImageCard_img {
    height: 95%;
    max-width: 98%;
}