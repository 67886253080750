.CreateRequestPage_master {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

}
.CreateRequestPage_sub {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}