.PropertyPage_large_master {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
}

.PropertyPage_large_body {
    width: 98%;
    max-width: 1200px;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PropertyPage_large_col {
    width: 49%;
    display: flex;
    flex-direction: column;
}

.PropertyPage_large_fab {
    position: fixed;
    bottom: 30px;
    right: 30px;
}
