.LoginPage_master {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;

}
.LoginPage_sub {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 99%;
    max-width: 600px;
    height: 100%;
}
.LoginPage_card {
    min-height: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.LoginPage_logo_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;

}

.LoginPage_logo {
    width: 40%;

}
.LoginPage_subtitle {
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
}


.LoginPage_textField {
    padding: 10px;
}

.LoginPage_fullwidth {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.LoginPage_button_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
}

.LoginPage_link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 500;
    padding-top: 10px;
}

.LoginPage_link_text:hover {
    cursor: pointer;
    
}