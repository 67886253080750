body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
  width: 100vw;
}


.LoginPage_master {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;

}
.LoginPage_sub {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 99%;
    max-width: 600px;
    height: 100%;
}
.LoginPage_card {
    min-height: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.LoginPage_logo_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;

}

.LoginPage_logo {
    width: 40%;

}
.LoginPage_subtitle {
    width: 100%;
    text-align: center;
    font-weight: 600;
    padding-bottom: 20px;
}


.LoginPage_textField {
    padding: 10px;
}

.LoginPage_fullwidth {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.LoginPage_button_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
}

.LoginPage_link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 500;
    padding-top: 10px;
}

.LoginPage_link_text:hover {
    cursor: pointer;
    
}
/* small screens */
@media screen and (max-width: 800px) {
  .SignUpPage_half_fields {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .SignUpPage_half {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

/* large screens */
@media screen and (min-width: 800px) {
  .SignUpPage_half_fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .SignUpPage_half {
    width: 49%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}



.Header_body {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding-left: 20px;
    padding-right: 20px;
}

.Header_logo {
    height: 40px;
    margin-top: 10px;
}

.Header_logo:hover {
    cursor: pointer;
}


.Header_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.HEader_right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}



/* Sidebar menu */

.nav-menu {
    background-color: rgb(18, 100, 100);
    color: white;
    width: 240px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    position: fixed;
    margin-top: 0px;
    top: 60px;
    transition: 850ms;
    right: -100%;
    box-shadow: 0px 5px 8px -9px silver;
    z-index: 1000;
    overflow: scroll;
    
}

.nav-menu.active {
    right: 0;
    transition: 350ms;
}

.Header_nav_menu_content {
    width: calc(100% - 10px);
    margin-left: 5px;
    height: calc(100% - 20px);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.CurrentRequestsCard_card {
    padding: 10px;
    margin-bottom: 20px;
}

.CurrentRequestsCard_title {
    width: 100%;
    font-weight: 600;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.CurrentRequestsCard_list {
    min-height: 60px;

}

.PastRequestsCard_card {
    padding: 10px;
    margin-bottom: 20px;
}

.PastRequestsCard_title {
    width: 100%;
    font-weight: 600;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.PastRequestsCard_list {

}

.PropertyAccessCard_card {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 20px;
    padding: 10px;
}

.PropertyAccessCard_title {
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.PropertyAccessCard_text {
    padding-bottom: 10px;
}

.PropertyAddressCard_card {
    padding: 10px;
    margin-bottom: 20px;
}

.PropertyAddressCard_title {
    width: 100%;
    font-weight: 600;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.PropertyAddressCard_line_top {
    font-weight: 600;
    width: 100%;
text-align: right;
font-size: large;
}

.PropertyAddressCard_line {
width: 100%;
text-align: right;
font-size: large;
}

.PropertyFloorPlanCard_card {
    padding: 10px;
    margin-bottom: 20px;

}

.PropertyFloorPlanCard_title {
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.PropertyFloorPlanCard_master {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.PropertyFloorPlanCard_sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
}

.PropertyImageCard_card {
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

}

.PropertyImageCard_sub {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

}

.PropertyImageCard_img {
    height: 95%;
    max-width: 98%;
}
.PropertyPage_large_master {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
}

.PropertyPage_large_body {
    width: 98%;
    max-width: 1200px;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PropertyPage_large_col {
    width: 49%;
    display: flex;
    flex-direction: column;
}

.PropertyPage_large_fab {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.PropertyPageContent_small {
    width: 98%;
    margin-left: 1%;
}

.PropertyPage_small_fab {
    position: fixed;
    bottom: 10px;
    right: 10px;
}

.Dashboard_title_parent {
    width: calc(100vw - 40px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 15px;

}
.Dashboard_title_sub {
    font-weight: 600;

}


.Dashboard_placeholder_parent {
    width: 100%;
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Dashboard_placeholder_child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    max-width: 800px;
    height: 100%;
}

.Dashboard_placeholder_card {
    width: 100%;
    min-height: 200px;
    text-align: center;
    align-items: center;

}

.Dashboard_placeholder_text_Opps {
    font-size: xx-large;
    font-weight: 700;
    padding-top: 20px;
    color: rgb(18, 100, 100);

}

.Dashboard_placeholder_text_Subtitle {
font-size: large;
font-weight: 600;
padding-top: 10px;
}


.Dashboard_placeholder_text_Text_parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}
.Dashboard_placeholder_text_Text {
    max-width: 600px;
    text-align: center;

}
.PropertiesPage_parent_div {
    width: 100vw;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.PropertiesPage_child_div {
    height: 100%;
    width: 98%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.PropertiesPage_card {
    width: 100%;
    min-height: 500px;
    padding: 10px;

}

.PropertiesPage_title {
    width: 100%;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    font-size: large;
}

.PropertiesPage_list {
    width: calc(100% - 20px);
    margin-left: 10px;
    max-height: inherit;
    height: 90%;
    margin-top: 10px;
    list-style-type: none;
}



.CreateRequestPage_master {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

}
.CreateRequestPage_sub {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.LoadingPage_parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 200px;
}

.LoadingPage_child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(18, 100, 100);

}

.RequestConfirmDetails_body {
    overflow: scroll;
    height: 70vh;
}


.RequestConfirmDetails_title {
    width: 100%;
    border-bottom: solid;
    border-width: 1px;
    border-color: lightgray;
    font-size: xx-large;
    color:rgb(18, 100, 100);
    font-weight: 600;
    margin-bottom: 20px;
}

.RequestConfirmDetails_textField {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.RequestConfirmDetails_img_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

}

.RequestConfirmDetails_img {
    max-width: 60%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 5px;
}


.RequestExtraDetails_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}
.RequestImageUpload_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
    border-bottom: solid;
    border-width: 1px;
}


.RequestImageUpload_img_list {
    height: 70vh;
    overflow: scroll;
}

/* small screens */
.RequestImageUpload_img_list {
    height: 70vh;
    overflow: scroll;
    -ms-scroll-chaining: chained;
        overscroll-behavior: auto;
}

.RequestImageUpload_loading_parent {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.RequestImageUpload_loading_child {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(18, 100, 100);

}

.RequestImageUpload_img_parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 10%;

}

.RequestImageUpload_img_badge {
    max-width: 60%;

}

.RequestImageUpload_img {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 5px;
}


.RequestRelatedSpace_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}


.RequestRelatedSpace_subtitle {
    font-size: medium;
    padding-top: 10px;
    padding-bottom: 10px;
    color: gray;
    font-weight: 500;
}
.RequestTitle_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}

.RequestTitle_textfield {


}
.RequestTradesmanType_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}

.RequestUrgency_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}

.RequestUrgency_subtitle {
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    color: gray;
    font-weight: 500;
}

.StartRequestScreen_logo_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.StartRequestScreen_logo_img {
    width: 60%;
    border-bottom: solid;
    border-color: gray;
    border-width: 1px;
    padding-bottom: 5px;
}

.StartRequestScreen_subtitle {
    font-size: xx-large;
    color: rgb(18, 100, 100);
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

}

.StartRequestScreen_text_parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.StartRequestScreen_text {
    width: 70%;
    text-align: center;
    font-size: large;
}

.StartRequestScreen_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.EditRequestPage_parent {
    height: calc(100vh - 60px);
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.EditRequestPage_child {
    height: 100%;
    width: 98%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .EditRequestPage_child {
        height: 100%;
        width: 98%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .EditRequestPage_parent {
        height:100%;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.EditRequestPage_card {
    min-height: 85vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.EditRequestPage_progress {
    color: rgb(18, 100, 100);
    padding-top: 2px;
}

.EditRequestPage_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}
.RequestTrackingPage_parent {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RequestTrackingPage_child {
    width: 98%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RequestTrackingPage_card {
    min-height: 400px;
    padding: 10px;

}

.RequestTrackingPage_card_progress {
    color: rgb(18, 100, 100);
}

.RequestTrackingPage_card_button_bar {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.RequestTrackingPage_img_card_div {
    padding-top: 10px;
    padding-bottom: 10px;


}

/* small screens  */
@media screen and (max-width: 1000px) {
    .RequestTrackingPage_img_card {
        width: calc(100% - 20px);
        height: 30vh;
    }  
}
@media screen and (min-width: 1000px) {
    .RequestTrackingPage_img_card {
        width: 80%;
        max-width: 600px;
        height: 30vh;
    }  
}



.RequestTrackingPage_textField {
    padding-top: 5px;
    padding-bottom: 5px;
}


.RequestTrackingPage_dialog {
    height: 80vh;
    padding: 10px;
}

.RequestTrackingPage_title {
    border-bottom: solid;
    border-width: 1px;
    border-width: 100%;
    border-color: gray;
    padding-bottom: 5px;
    font-weight: 600;

}

.RequestTrackingPage_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: rgb(18, 100, 100)
}

.RequestTrackingPage_sub_card {
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    height: 300px;

}

.RequestTrackingPage_subtitle {
    font-size: large;
    font-weight: 600;
    border-bottom: solid;
    border-width: 1px;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 5px;

}

.RequestTrackingPage_message {
    width: 100%;
    height: 260px;
    overflow: scroll;
    scroll-behavior: smooth;

}

.MultiImageDisplay_card {
    width: 100%;
    height: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MultiImageDisplay_navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

}

.MultiImageDisplay_image_div {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.MultiImageDisplay_image_column {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MultiImageDisplay_img {
    height: 100%;
    max-width: 100%;
    object-fit:scale-down;

}


.MultiImageDisplay_icon_right {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}


.Timeline_list {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px;

}


.Timeline_list::after {
    background-color: rgb(18, 100, 100);
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% - 110px);
    margin-top: 50px;

}

.TimelineItem_item {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 23px) ;
    padding-bottom: 10px;
}


.TimelineItem_item_content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 100%;
    text-align: right;
    height: 80px;
}
.TimelineItem_item_content::after {
    content: ' ';
    width: 15px;
    height: 15px;
    position: absolute;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    left: -7.5px;
    top: calc(50% - 7.5px);
    background-color: white;
    box-shadow:  -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.TimelineItem_item_content .circle {
    color: rgb(18, 100, 100);
     z-index: 100; 
     border: solid;
     background-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px; 
    position: absolute;
    top: calc(50% - 13px);
    left: -41px;
    font-size: large;
    font-weight: 500;
}


.TimelineItem_time {
    font-size: small;
    color: gray;
}

.TimelineItem_title {
    padding-top: 5px;
    font-size: large;
    font-weight: 600;
}




/* pending requests */

.TimelineItem_item-incomplete {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 23px) ;
    padding-bottom: 10px;
}


.TimelineItem_item_content-incomplete {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 100%;
    text-align: right;
    height: 80px;
}

.TimelineItem_item_content-incomplete .circle {
    color: gray;
     z-index: 100; 
     border: solid;
     background-color: rgb(238, 238, 238);
    border-radius: 50%;
    width: 20px;
    height: 20px; 
    position: absolute;
    top: calc(50% - 13px);
    left: -41px;
    font-size: large;
    font-weight: 500;
}

.TimelineItem_item_content-incomplete::after {
    content: ' ';
    width: 15px;
    height: 15px;
    position: absolute;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    left: -7.5px;
    top: calc(50% - 7.5px);
    background-color: rgb(238, 238, 238);
    box-shadow:  -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.TimelineItem_title-incomplete {
    padding-top: 5px;
    font-size: large;
    font-weight: 600;
}
