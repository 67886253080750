.RequestImageUpload_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
    border-bottom: solid;
    border-width: 1px;
}


.RequestImageUpload_img_list {
    height: 70vh;
    overflow: scroll;
}

/* small screens */
.RequestImageUpload_img_list {
    height: 70vh;
    overflow: scroll;
    overscroll-behavior: auto;
}

.RequestImageUpload_loading_parent {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.RequestImageUpload_loading_child {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(18, 100, 100);

}

.RequestImageUpload_img_parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 10%;

}

.RequestImageUpload_img_badge {
    max-width: 60%;

}

.RequestImageUpload_img {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    padding: 5px;
}

