.PropertiesPage_parent_div {
    width: 100vw;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.PropertiesPage_child_div {
    height: 100%;
    width: 98%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.PropertiesPage_card {
    width: 100%;
    min-height: 500px;
    padding: 10px;

}

.PropertiesPage_title {
    width: 100%;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    font-size: large;
}

.PropertiesPage_list {
    width: calc(100% - 20px);
    margin-left: 10px;
    max-height: inherit;
    height: 90%;
    margin-top: 10px;
    list-style-type: none;
}

