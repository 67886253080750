.LoadingPage_parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 200px;
}

.LoadingPage_child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(18, 100, 100);

}
