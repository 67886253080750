/* small screens */
@media screen and (max-width: 800px) {
  .SignUpPage_half_fields {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .SignUpPage_half {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

/* large screens */
@media screen and (min-width: 800px) {
  .SignUpPage_half_fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .SignUpPage_half {
    width: 49%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}


