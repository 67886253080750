.MultiImageDisplay_card {
    width: 100%;
    height: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MultiImageDisplay_navigation {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

}

.MultiImageDisplay_image_div {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.MultiImageDisplay_image_column {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MultiImageDisplay_img {
    height: 100%;
    max-width: 100%;
    object-fit:scale-down;

}


.MultiImageDisplay_icon_right {
    transform: rotate(180deg);
}
