
.Dashboard_title_parent {
    width: calc(100vw - 40px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 15px;

}
.Dashboard_title_sub {
    font-weight: 600;

}


.Dashboard_placeholder_parent {
    width: 100%;
    height: calc(100vh - 95px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Dashboard_placeholder_child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    max-width: 800px;
    height: 100%;
}

.Dashboard_placeholder_card {
    width: 100%;
    min-height: 200px;
    text-align: center;
    align-items: center;

}

.Dashboard_placeholder_text_Opps {
    font-size: xx-large;
    font-weight: 700;
    padding-top: 20px;
    color: rgb(18, 100, 100);

}

.Dashboard_placeholder_text_Subtitle {
font-size: large;
font-weight: 600;
padding-top: 10px;
}


.Dashboard_placeholder_text_Text_parent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}
.Dashboard_placeholder_text_Text {
    max-width: 600px;
    text-align: center;

}