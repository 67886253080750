.PropertyAddressCard_card {
    padding: 10px;
    margin-bottom: 20px;
}

.PropertyAddressCard_title {
    width: 100%;
    font-weight: 600;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.PropertyAddressCard_line_top {
    font-weight: 600;
    width: 100%;
text-align: right;
font-size: large;
}

.PropertyAddressCard_line {
width: 100%;
text-align: right;
font-size: large;
}
