.RequestRelatedSpace_title {
    font-size: xx-large;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    color: rgb(18, 100, 100);
}


.RequestRelatedSpace_subtitle {
    font-size: medium;
    padding-top: 10px;
    padding-bottom: 10px;
    color: gray;
    font-weight: 500;
}