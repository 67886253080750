.RequestTrackingPage_parent {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.RequestTrackingPage_child {
    width: 98%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RequestTrackingPage_card {
    min-height: 400px;
    padding: 10px;

}

.RequestTrackingPage_card_progress {
    color: rgb(18, 100, 100);
}

.RequestTrackingPage_card_button_bar {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.RequestTrackingPage_img_card_div {
    padding-top: 10px;
    padding-bottom: 10px;


}

/* small screens  */
@media screen and (max-width: 1000px) {
    .RequestTrackingPage_img_card {
        width: calc(100% - 20px);
        height: 30vh;
    }  
}
@media screen and (min-width: 1000px) {
    .RequestTrackingPage_img_card {
        width: 80%;
        max-width: 600px;
        height: 30vh;
    }  
}



.RequestTrackingPage_textField {
    padding-top: 5px;
    padding-bottom: 5px;
}


.RequestTrackingPage_dialog {
    height: 80vh;
    padding: 10px;
}

.RequestTrackingPage_title {
    border-bottom: solid;
    border-width: 1px;
    border-width: 100%;
    border-color: gray;
    padding-bottom: 5px;
    font-weight: 600;

}

.RequestTrackingPage_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: rgb(18, 100, 100)
}

.RequestTrackingPage_sub_card {
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    height: 300px;

}

.RequestTrackingPage_subtitle {
    font-size: large;
    font-weight: 600;
    border-bottom: solid;
    border-width: 1px;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 5px;

}

.RequestTrackingPage_message {
    width: 100%;
    height: 260px;
    overflow: scroll;
    scroll-behavior: smooth;

}
