
.Timeline_list {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px;

}


.Timeline_list::after {
    background-color: rgb(18, 100, 100);
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% - 110px);
    margin-top: 50px;

}

.TimelineItem_item {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 23px) ;
    padding-bottom: 10px;
}


.TimelineItem_item_content {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 100%;
    text-align: right;
    height: 80px;
}
.TimelineItem_item_content::after {
    content: ' ';
    width: 15px;
    height: 15px;
    position: absolute;
    transform: rotate(45deg);
    left: -7.5px;
    top: calc(50% - 7.5px);
    background-color: white;
    box-shadow:  -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.TimelineItem_item_content .circle {
    color: rgb(18, 100, 100);
     z-index: 100; 
     border: solid;
     background-color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px; 
    position: absolute;
    top: calc(50% - 13px);
    left: -41px;
    font-size: large;
    font-weight: 500;
}


.TimelineItem_time {
    font-size: small;
    color: gray;
}

.TimelineItem_title {
    padding-top: 5px;
    font-size: large;
    font-weight: 600;
}




/* pending requests */

.TimelineItem_item-incomplete {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    position: relative;
    width: calc(100% - 23px) ;
    padding-bottom: 10px;
}


.TimelineItem_item_content-incomplete {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 100%;
    text-align: right;
    height: 80px;
}

.TimelineItem_item_content-incomplete .circle {
    color: gray;
     z-index: 100; 
     border: solid;
     background-color: rgb(238, 238, 238);
    border-radius: 50%;
    width: 20px;
    height: 20px; 
    position: absolute;
    top: calc(50% - 13px);
    left: -41px;
    font-size: large;
    font-weight: 500;
}

.TimelineItem_item_content-incomplete::after {
    content: ' ';
    width: 15px;
    height: 15px;
    position: absolute;
    transform: rotate(45deg);
    left: -7.5px;
    top: calc(50% - 7.5px);
    background-color: rgb(238, 238, 238);
    box-shadow:  -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.TimelineItem_title-incomplete {
    padding-top: 5px;
    font-size: large;
    font-weight: 600;
}