.Header_body {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding-left: 20px;
    padding-right: 20px;
}

.Header_logo {
    height: 40px;
    margin-top: 10px;
}

.Header_logo:hover {
    cursor: pointer;
}


.Header_right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.HEader_right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}



/* Sidebar menu */

.nav-menu {
    background-color: rgb(18, 100, 100);
    color: white;
    width: 240px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    position: fixed;
    margin-top: 0px;
    top: 60px;
    transition: 850ms;
    right: -100%;
    box-shadow: 0px 5px 8px -9px silver;
    z-index: 1000;
    overflow: scroll;
    
}

.nav-menu.active {
    right: 0;
    transition: 350ms;
}

.Header_nav_menu_content {
    width: calc(100% - 10px);
    margin-left: 5px;
    height: calc(100% - 20px);
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}