.EditRequestPage_parent {
    height: calc(100vh - 60px);
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.EditRequestPage_child {
    height: 100%;
    width: 98%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .EditRequestPage_child {
        height: 100%;
        width: 98%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .EditRequestPage_parent {
        height:100%;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.EditRequestPage_card {
    min-height: 85vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.EditRequestPage_progress {
    color: rgb(18, 100, 100);
    padding-top: 2px;
}

.EditRequestPage_buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
