.StartRequestScreen_logo_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.StartRequestScreen_logo_img {
    width: 60%;
    border-bottom: solid;
    border-color: gray;
    border-width: 1px;
    padding-bottom: 5px;
}

.StartRequestScreen_subtitle {
    font-size: xx-large;
    color: rgb(18, 100, 100);
    font-weight: 600;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

}

.StartRequestScreen_text_parent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.StartRequestScreen_text {
    width: 70%;
    text-align: center;
    font-size: large;
}

.StartRequestScreen_button_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}
